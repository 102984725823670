import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { HashRouter, Route } from 'react-router-dom';

import { createClient } from './apollo';
import environment from './libs/environment';

import 'focus-visible';
import './index.scss';

import Home from './home';

const rootElement =
  typeof document === 'object' ? document.getElementById('root') : undefined;

if (rootElement) {
  ReactDOM.render(
    <ApolloProvider
      client={createClient('authToken', environment.GATEWAY_ENDPOINT)}
    >
      <HashRouter>
        <div>
          <Route exact path="/" component={Home} />
        </div>
      </HashRouter>
    </ApolloProvider>,
    rootElement,
  );
}
