// @flow

import * as React from 'react';
import { Link } from '@ansarada/ace-react';
import styles from './home.scss';

import User from './containers/user';

const Home = () => (
  <div className={styles.app}>
    <p>ACE SPA!</p>
    <Link href="http://ace-react.ansarada.com/#/">Ace React</Link>
    <User />
  </div>
);

export default Home;
