// @flow
import React from 'react';
import gql from 'graphql-tag';

import type { User as UserT } from 'App';

type Props = {
  user: UserT,
};

const User = ({ user: { firstName, lastName } }: Props) => (
  <div>
    <div>Fetched the following user from Gateway:</div>
    <div>
      {firstName} {lastName}
    </div>
  </div>
);

User.fragments = {
  user: gql`
    fragment User on User {
      firstName
      lastName
    }
  `,
};

export default User;
