import { Query } from 'react-apollo';
import React from 'react';
import gql from 'graphql-tag';

import User from '../components/user';

const GET_USER_QUERY = gql`
  query GetUserQuery($id: ID!) {
    user: getUser(id: $id) {
      ...User
    }
  }

  ${User.fragments.user}
`;

const UserContainer = () => (
  <Query variables={{ id: '0' }} query={GET_USER_QUERY}>
    {({ loading, error, data }) => {
      if (loading) {
        return <div>loading...</div>;
      }

      if (error) {
        return <div>Oopsy!! {JSON.stringify(error)}</div>;
      }

      return <User user={data.user} />;
    }}
  </Query>
);
export default UserContainer;
