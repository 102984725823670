// @flow

import ApolloClient from 'apollo-boost';

const createClient = (authToken: string, uri: string) =>
  new ApolloClient({
    uri,
    request: async (operation: any) => {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    },
  });

export { createClient };
